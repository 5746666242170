import store from '@/store';
import router from '@/router';
function convertStatus(_status) {
    switch (_status) {
        case "D": return "Disconnect"
        case "C": return "Connect"
        case "E": return "Error"
        case "U": return "Unknown"
        default: return _status
    }
}

function convertType(_type, _typeDtl) {
    if(_type == "D") {
        if(_typeDtl == "ENV") return "Envelope deposit";
        else if(_typeDtl == "MIX") return "Deposit(Mix)";
        else return "Deposit";  //DEP 또는 그외 값

    } else if(_type == "W") {
        return "Withdrawal";

    } else if(_type == "X") {
        if(_typeDtl == "EXD") return "Exchange deposit";
        else if(_typeDtl == "EXW") return "Exchange withdrawal";
        else return "Exchange";

    } else {
        return _type;
    }
}

function convertTypeDetail(_type) {
    switch (_type) {
        case "DEP": return "Deposit"
        case "ENV": return "Envelope"
        case "WDR": return "Withdrawal"
        case "EXD": return "Deposit"
        case "EXW": return "Withdrawal"
        default: return _type
    }
}

function convertModel(_model) {
    switch (_model) {
        case "D": return "Deposit"
        case "W": return "Withdraw"
        case "R": return "Recycler"
        default: return _model
    }
}

function convertSupport(_support) {
    switch (_support) {
        case "B": return "Banknote"
        case "C": return "Coin"
        default: return _support
    }
}

function c_setLocalDateTime(_date) {
    const t = new Date(_date);
    const year = t.getFullYear();
    const mon = t.getMonth() + 1;
    const date = t.getDate();
    const hour = t.getHours();
    const min = t.getMinutes();
    const sec = t.getSeconds();

    return [year, 
        (mon > 9 ? mon : "0" + mon),
        (date > 9 ? date : "0" + date)].join("-")
     + " "
     + [(hour > 9 ? hour : "0" + hour),
        (min > 9 ? min : "0" + min),
        (sec > 9 ? sec : "0" + sec)].join(":")
}

function c_getLocalDateTime(diff = null) {
    const t = new Date();
    const year = t.getFullYear();
    const mon = t.getMonth() + 1 + (diff == null || diff.month == undefined ? 0 : diff.month);
    const date = t.getDate() + (diff == null || diff.date == undefined ? 0 : diff.date);
    const hour = t.getHours();
    const min = t.getMinutes();
    const sec = t.getSeconds();

    return [year, 
        (mon > 9 ? mon : "0" + mon),
        (date > 9 ? date : "0" + date)].join("-")
     + " "
     + [(hour > 9 ? hour : "0" + hour),
        (min > 9 ? min : "0" + min),
        (sec > 9 ? sec : "0" + sec)].join(":")
}

function c_getLocalDate(t = new Date()) {
    const year = t.getFullYear();
    const mon = t.getMonth() + 1;
    const date = t.getDate();

    return [year, 
        (mon > 9 ? mon : "0" + mon),
        (date > 9 ? date : "0" + date)].join("-");
}

function c_getUserTimezone() {
    const timeZoneOffset = new Date().getTimezoneOffset() / 60;
    const timeZoneSign = timeZoneOffset > 0 ? '-' : '+';
    const timeZoneInfo = timeZoneSign + Math.abs(timeZoneOffset);
    return timeZoneInfo
}

function parseUTCDate(utcString) {
    return new Date(Date.UTC(
      parseInt(utcString.substring(0, 4)), // Year
      parseInt(utcString.substring(5, 7)) - 1, // Month (0-based)
      parseInt(utcString.substring(8, 10)), // Day
      parseInt(utcString.substring(11, 13)), // Hours
      parseInt(utcString.substring(14, 16)), // Minutes
      parseInt(utcString.substring(17, 19)) // Seconds
    ));
}

// Function to calculate time difference in seconds
function getTimeDifferenceInSeconds(lastConnectUTC) {
    if(lastConnectUTC == null) return 0;

    const currentUTC = new Date();
    const lastConnectDate = parseUTCDate(lastConnectUTC);
    const timeDifferenceInSeconds = (currentUTC - lastConnectDate) / 1000;
    return timeDifferenceInSeconds;
}

// Function to add timeOffset to lastConnectUTC and format the result
function calculateNewTime(lastConnectUTC, timeOffset) {
    if(lastConnectUTC == null) return "-";

    //const lastConnectDate = parseUTCDate(lastConnectUTC);
    const lastConnectDate = new Date(lastConnectUTC);
    const newTimeInMilliseconds = lastConnectDate.getTime() + timeOffset * 1000;
    //const newTime = new Date(newTimeInMilliseconds);
    //const formattedNewTime = newTime.toLocaleString(undefined, { timeZone: 'UTC' });

    return newTimeInMilliseconds;
}

/* const logout = async() => {
    await store.commit('userStore/logout')
    .then(() => router.push({name: 'Login'}))
} */
async function logout() {
    //alert("Your session has expired.")

    await store.dispatch('userStore/reset')
    .then(() => router.push({name: 'Login'}))
}

const EXPORT_LIMIT = 2000;

export {
    convertStatus, convertType, convertTypeDetail,
    convertModel, convertSupport,
    c_setLocalDateTime, c_getLocalDateTime, c_getLocalDate, c_getUserTimezone,
    getTimeDifferenceInSeconds, calculateNewTime,
    logout,
    EXPORT_LIMIT,
}