import { createStore } from 'vuex'
import userStore from "./modules/userStore.js";
import scheduleStore from "./modules/scheduleStore.js";
import treeStore from "./modules/treeStore.js";


const store = createStore({
    modules: { userStore, scheduleStore, treeStore },
})

export default store;

//정리 아주잘돼있음 (11.filter, 13.Jest 등) https://jess2.xyz/vue/vue-tip/#7-%EC%83%81%ED%83%9C-%EA%B4%80%EB%A6%AC%EC%99%80-store
//토큰 만료 https://han-um.tistory.com/17
//vue store 모듈화 https://developerjournal.tistory.com/11?category=912023
//스타일 가이드 https://kr.vuejs.org/v2/style-guide/index.html

//const HOST = ""


/*const store = new Vuex.Store({
    state: {    
        //여러 컴포넌트에서 공유할 공통의 상태. this.$store.state로 count 변수에 접근가능
        count: 1    
    },
    mutations: {  
        //state를 변경시키기 위한 유일한 방법. 동기적 로직 정의할 수 있다.
        doubleCount(state){
            state.count = state * 2
        },
        multiCountAndNumber(state, payload) {
            state.count = state.count * payload
        },
        setAnimalList(state, payload) { 
            state.animalList = payload 
        }
    }, 
    getters: {
        // 공통의 상태를 계산하고, state값 반환
        //공동의 상태를 가공하는 경우가 여러 컴포넌트에 있을 때, 
        //각 컴포넌트의 computed에서 같은 코드를 여러번 쓸 것을 
        //getters에서 한번 제공하여 다른 컴포넌트에서 똑같이 쓸 수 있기 때문에 
        getLowerCaseAlphabet(state) { 
            return state.alphabet.toLowerCase(); 
        }, getTrimAlphabet(state) { 
            return state.alphabet.trim(); 
        }
    }, 
    actions: {
        // 비동기적 로직 정의
        //async/await 키워드 사용 가능
        //actions 내부에서 mutations에 대한 commit 가능 
        async getAllAnimals({ commit }) { 
            let response = null 
            try { 
                //response = await axios(HOST + "/api/getAllAnimal") 
            } catch (e) { 
                console.error(e) 
            } 
            commit("setAnimalList", response.data)  //commit 프로퍼티 활성화시켜 내
        },
    }
})*/
