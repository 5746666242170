const treeStore = {
  namespaced: true,
  state: {
      init: true, //최초 호출여부
      roots: [],
      nodeIds: [],
      nodes: {},
      machineTot:0
  },
  getters: {
      'init': state => state.init,
      'roots': state => state.roots,
      'nodeIds': state => state.nodeIds,
      'nodes': state => state.nodes,
      'machineTot': state => state.machineTot,
  },
  mutations: {
      setTree(state, item) {
        state.init = false;
        state.roots = item['roots']
        state.nodeIds = item['nodeIds']
        state.nodes = item['nodes']
        state.machineTot = item['machineTot']
      },
      checkReset(state) {
        state['nodeIds'].forEach(id => {
          state['nodes'][id]["state"] = {opened: true}
        });
      }
    }
}

export default treeStore