

/*
import { axiosService } from "./index";
const loginUser = (userData, success, error) => {
  return axiosService
    .post("/api/v1/users/login", userData)
    .then((res) => success(res.data))
    .catch(error);
};

const logoutUser = (userId) => {
  return axiosService.post("/api/v1/users/logout/" + userId);
};

const checkUser = async () => {
  return axiosService.get("/api/v1/users/check");
};

const ConnectionTest = (success, error) => {
  return axiosService
    .get("/test/")
    .then((res) => success(res.data))
    .catch(error);
};
*/
//-----------------------fetchConfig 사용
import { getAxiosService } from "./index";

const loginUser = (userData, success, error) => {
    return getAxiosService()
    .then(service => service.post('/api/v1/users/login', userData))
    .then(res => success(res.data))
    .catch(error);
}

const logoutUser = (userId) => {
    return getAxiosService()
    .then(service => service.post('/api/v1/users/logout/' + userId));
}

const checkUser = async () => {
    const service = await getAxiosService();
    return await service.get('/api/v1/users/check');
}

const ConnectionTest = (success,error) => {
    return getAxiosService()
    .then(service => service.get('/test/'))
    .then(res => success(res.data))
    .catch(error);
}

export { loginUser, logoutUser, checkUser, ConnectionTest };
