<template>
  <v-app>
    <v-main>
      <div>
        <template v-if="isLogin">
          <main-menu />
          <div class="wrap">
            <div class="content_box">
              <main-header :title="title" />
              <router-view @setTitle="setTitle" />
            </div>
          </div>
        </template>
        <template v-else>
          <router-view @setTitle="setTitle" />
        </template>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import MainHeader from './components/MainHeader.vue'
import MainMenu from './components/MainMenu.vue'
//import TestOne from './components/TestOne.vue'
//import HelloWorld from './components/HelloWorld.vue'

export default {
  /*name: 'App',
  /created() {
    const token = this.$store.state.token

    if(token == null) {
      //토큰없으면 로그인 페이지로
      //이미 로그인 페이지가 떠있는 상태에서 새로 고침하면 중복 에러 떠서 이렇게 처리함
      this.$router.push({name: 'Login'}).catch(() => {}); 
    }
  }, */
  components: {
    //HelloWorld
    MainHeader,
    MainMenu,
  },
  computed: {
    isLogin() {  
       //if(this.$store.getters["userStore/isLogin"] || this.$cookies.get('JSESSIONID') !== null)
       if(this.$store.getters["userStore/isLogin"])
          return true;
      else
          return false 
    },
  },
  methods: {
    setTitle(val) {
      this.title = val
    } 
  },
  data() {
    return {
      title: ''
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
}
.v-main {
  background-color: #ebebeb;
}
</style>
