//import Vue from 'vue'
import { createI18n } from 'vue-i18n'
import en from './en.json'
import ko from './ko.json'

/*
 * 안내문구 msg
 * 버튼 btn
 * 테이블 컬럼명 col
 * 일반 txt
 */
const datetimeFormats = {
    'en-US': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    'ko-KR': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    }
  }

export const i18n = createI18n({
    // legacy: false, // you must set `false`, to use Composition API
    locale: 'en-US',
    fallbackLocale: 'ko-KR',
    datetimeFormats,
    messages: {'en-US': en, 'ko-KR': ko},
})