import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
//import axios from 'axios'
import VueCookies from 'vue3-cookies'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { i18n } from './locales/i18n'
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import './assets/css/reset.css'
import './assets/css/common.css'
import common from './assets/js/common.js'

loadFonts()

const app = createApp(App)
//app.config.globalProperties.axios = axios
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(vuetify)
  .use(VueCookies)
  // .use(VueCookies, {
  //   expireTimes: "7d",
  //   path: "/",
  //   domain: "",
  //   secure: true, //only https works
  //   sameSite: "None",
  // })
  .use(Vue3SimpleHtml2pdf)
  .use(common)

app.mount('#app')
