const scheduleStore = {
    namespaced: true,
    state: {
        name: '',
        date: new Date(),
        files: [],
        fileStore: {
            modelName: '',
            modelId: -1,
            fileName: '',
            packageId: -1,
            machines: [],
            singleFile: null, //파일 바로 업로드
        },
        editMode: false
    },
    getters: {
        'name': state => state.name,
        'date': state => state.date,
        'modelName': state => state.fileStore.modelName,
        'modelId': state => state.fileStore.modelId,
        'fileName': state => state.fileStore.fileName,
        'packageId': state => state.fileStore.packageId,
        'machines': state => state.fileStore.machines,
        'files': state => state.files,
        'editMode': state => state.editMode,
        'singleFile': state => state.fileStore.singleFile //파일 바로 업로드
    },
    mutations: {
        reset(state) {
            state.name = ''
            state.date = new Date()
            state.files = []
            state.fileStore = {
                modelName: '',
                modelId: -1,
                packageId: -1,
                fileName: '',
                machines: [],
                singleFile: null
            }
            state.editMode = false
        },
        setInfo (state, item) {
            state.name = item['name']
            state.date = item['date']
        },
        setFile (state, item) {
            state.fileStore.modelName = item['modelName']
            state.fileStore.modelId = item['modelId']
            state.fileStore.packageId = item['packageId']
            state.fileStore.fileName = item['fileName']
        },
        setSingleFile (state, item) { //파일 바로 업로드
            state.fileStore.singleFile = item
        },
        setFileName (state, item) { //파일 바로 업로드
            state.fileStore.fileName = item
        },
        setMachine (state, item) {
            state.fileStore.machines = item
        },
        addFileStore (state) {
            state.files.push(state.fileStore)
        },
        resetFileStore (state) {
            state.fileStore = {
                modelName: '',
                modelId: -1,
                packageId: -1,
                fileName: '',
                machines: [],
                singleFile: null
            }
        },
        setEditMode(state) {
            state.editMode = true
        },
        setAddMode(state) {
            state.editMode = false
        },
        removeFile(state, idx) {
            state.files.splice(idx, 1)
        },
        editFile(state, idx) {
            state.fileStore = state.files[idx]
        },
        editMachine(state, idx) {
            state.fileStore = state.files[idx]
        }
    },
    actions: {
      }
}

export default scheduleStore