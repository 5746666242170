//import { logout } from '@/store/modules/userStore';
//import store from '@/store';
//import router from '@/router';
//import { logout } from "@/assets/js/common.js";

export function setInterceptors(axiosService) {
    axiosService.interceptors.request.use(
        function (config) {
            // 요청을 보내기 전에 어떤 처리를 할 수 있다.
            //config.headers.Authorization = store.state.session;
            return config;
        }, function (error) {
            // 요청이 잘못되었을 때 에러가 컴포넌트 단으로 오기 전에 어떤 처리를 할 수 있다.
            return Promise.reject(error);
        });
    
    axiosService.interceptors.response.use(
        (response) => {
            // 서버에 요청을 보내고 나서 응답을 받기 전에 어떤 처리를 할 수 있다.
            return response;
        }, async (res) => {
            // 응답이 에러인 경우에 미리 전처리할 수 있다.
            const error = res.response.data
            /* if(error.code === 'NO_SESSION') {
                console.log("세션만료")
                //router.push({name: 'Login'})
                //logout()
                //await store.commit('userStore/logout')
                //.then(() => this.$router.push({name: 'Login'}))
                
                store.dispatch('userStore/logout')
                this.$store.dispatch('userStore/logout');
                router.push({name: 'Login'})
            } */            
            return Promise.reject(error);
        });

    return axiosService;
}