<template>
    <div class="gnb_block">
        <div :class="'wrap' + (subOpen? '' : ' content_short')">
            <div :class="'nav_bar' + (subOpen? '' : ' header_close')">
                <header class="nav_bar_header">
                    <div class="btn_hamburger">
                        <button v-on:click="expandMenu(!subOpen)" />
                    </div>
                    <div class="title">
                        <div class="img_logo">
                            <img :src="require('@/assets/images/gnb/Kisan_Logo_white.png')" />
                        </div>
                        <span>CDMS</span>
                    </div>
                    <!--<figure class="img_logo">
                        <img src={logoicon} alt="logo" draggable="false" class="" />
                    </figure>
                    <div class="user_info mobile">
                        <img src={usericon} alt="plofile" class="user_profile_img" />
                        <div class="user_data">
                            <p class="name">{{ $store.userStore.state.username }}</p>
                            <p class="date">{{ new Date() }}</p>
                            <button class="logout_btn"> LOGOUT </button>
                        </div>
                    </div> -->
                </header>
                <div class="nav_bar_body">
                    <div class="body_nav_icon">
                        <nav class="nav_icon_gnb">
                            <ul class="gnb_list">
                                <template v-for="(gnb, idx) in gnbList" :key="gnb.key">
                                    <template v-if="gnb.subList">
                                        <li :class="'list_'+(idx+1) + (gnb.target===activeGnb? ' select':'')" 
                                            @click="[expandMenu(true), clickGnb(gnb.target)]">
                                            <div>
                                                <div>{{ $t(gnb.title) }}</div>
                                                <span>{{ $t(gnb.title) }}</span>
                                            </div>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <router-link :to="gnb.target">
                                            <li :class="'list_'+(idx+1) + (gnb.target===activeGnb? ' select':'')" 
                                                @click="expandMenu(false), clickGnb(gnb.target)">
                                                <div>
                                                    <div>{{ $t(gnb.title) }}</div>
                                                    <span>{{ $t(gnb.title) }}</span>
                                                </div>
                                            </li>
                                        </router-link>
                                    </template>
                                </template>
                            </ul>
                        </nav>
                        <!-- <nav class="nav_icon_snb">
                            <ul class="snb_list">
                                <li class="list_1">
                                    <div>
                                        <img :src="require('@/assets/images/gnb/com-menu-info-icon@2x.png')" />
                                        <span>Quick menu1</span>
                                    </div>
                                </li>
                                <li class="list_2">
                                    <div>
                                        <img :src="require('@/assets/images/gnb/com-menu-upgrade-icon@2x.png')" />
                                        <span>Quick menu2</span>
                                    </div>
                                </li>
                            </ul>
                        </nav> -->
                    </div>
                    <div class="body_nav_text">
                       <ul v-for="gnb in gnbList" 
                            class="lnb_list" 
                            :style="{'display': gnb.target === activeGnb ? 'block' : 'none' }"
                            :key="'lnb_'+gnb.key" >
                                <!-- <li v-for="sub in gnb.subList" 
                                    v-on:click="expandMenu(false)"
                                    :key="sub.key" >
                                    <router-link :to="gnb.target+sub.target">{{ $t(sub.title) }}</router-link>
                                </li> -->
                                <template v-for="sub in gnb.subList">
                                    <template v-if="sub.hasOwnProperty('subList')">
                                        <li v-on:click="expandMenu(false)"
                                            :key="sub.key" >
                                            <a>{{ $t(sub.title) }}</a>
                                        </li>
                                        <ul class="lnb_sub_list" :key="sub.key+'Ul'">
                                            <li v-for="sub2 in sub.subList" 
                                                v-on:click="expandMenu(false)"
                                                :key="sub2.key" >
                                                <router-link :to="gnb.target+sub2.target">{{ $t(sub2.title) }}</router-link>
                                            </li>
                                        </ul>
                                    </template>
                                    <template v-else>
                                        <li v-on:click="expandMenu(false)"
                                            :key="sub.key" >
                                            <router-link :to="gnb.target+sub.target">{{ $t(sub.title) }}</router-link>
                                        </li>
                                    </template>

                                </template>
                        </ul> 
                        <!-- <router-link v-for="gnb in gnbList" 
                                    :key="'lnb_'+gnb.key"
                                    :to="gnb.target"
                                    custom
                                    v-slot="{ href, route, navigate, isActive, isExactActive }">
                            <ul class="lnb_list" :style="[isActive ? 'block' : 'none']">
                                <li v-for="sub in gnb.subList" :key="sub.key" v-on:click="expandMenu(false)"
                                     :class="[isExactActive && 'select']">
                                    <a :href="href+sub.target" @click="navigate">{{ sub.title }}</a>
                                </li>
                            </ul>
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
//import router from "@/router"
const gnbList = [
    /*{
        target: '/home',
        title: 'menu.home',
        key: 'menuHome',
    },*/
    // {
    //     target: '/dashboard',
    //     title: 'menu.dashboard',
    //     key: 'menuDashboard',
    //     subList: [
    //         {
    //             target: '/integrated',
    //             title: 'menu.d-itg',
    //             key: 'menuItg'
    //         },
    //         // {
    //         //     target: '/machine',
    //         //     title: 'menu.d-mch',
    //         //     key: 'menuMch'
    //         // },
    //         // {
    //         //     target: '/software',
    //         //     title: 'menu.d-sw',
    //         //     key: 'menuSw',
    //         // },
    //         // {
    //         //     target: '/management',
    //         //     title: 'menu.d-mgt',
    //         //     key: 'menuDbMgt',
    //         // },
    //     ]
    // },
    {
        target: '/cash-flow',
        title: 'menu.cash',
        key: 'menuCashflow',
        subList: [
            {
                target: '/transaction',
                title: 'menu.c-trans',
                key: 'menuTrans'
            },
            {
                target: '/workload',
                title: 'menu.c-workload',
                key: 'menuWorkload',
            },
            {
                target: '/collection',
                title: 'menu.c-collection',
                key: 'menuCollection',
            },
        ]
    },
    {
        target: '/asset',
        title: 'menu.asset',
        key: 'menuAsset',
        subList: [
            {
                title: 'menu.asset-1',
                key: 'menuAsset1',
                subList: [
                    {
                        target: '/status',
                        title: 'menu.a-status',
                        key: 'menuStatus'
                    },
                    {
                        target: '/capacity',
                        title: 'menu.a-cap',
                        key: 'menuCap'
                    },
                    ...(process.env.VUE_APP_TREE === 'group' ? [{
                        target: '/group',
                        title: 'menu.a-group',
                        key: 'menuGrp'
                    }] : []),
/*                     {
                        target: '/error',
                        title: 'menu.a-err',
                        key: 'menuErr'
                    }, */
                ]
            },
            {
                title: 'menu.asset-2',
                key: 'menuAsset2',
                subList: [
                    // {
                    //     target: '/upgrade',
                    //     title: 'menu.a-upd',
                    //     key: 'menuUpd'
                    // },
                    {
                        target: '/package',
                        title: 'menu.a-pck',
                        key: 'menuPck'
                    },
                    // {
                    //     target: '/log',
                    //     title: 'menu.a-log',
                    //     key: 'menuLog'
                    // },
                    {
                        target: '/schedule',
                        title: 'menu.a-sch',
                        key: 'menuSch'
                    },
                ]
            },
        ]
    },
    {
        target: '/manage',
        title: 'menu.user',
        key: 'menuMgt',
        subList: [
            {
                target: '/branch',
                title: 'menu.b-mgt',
                key: 'menuBrnc'
            },
            {
                target: '/location',
                title: 'menu.l-mgt',
                key: 'menuL'
            },
            // {
            //     target: '/user',
            //     title: 'menu.u-mgt',
            //     key: 'menuUser'
            // },
        ]
    },
];

export default {
    watch: {
        '$route'(to) {
        // $route 객체가 변경될 때마다 호출되는 콜백 함수
            const fullPath = to.fullPath;
            this.activeGnb = '/' + fullPath.split('/')[1]
        }
    },
    // mounted() {
    //     this.activeGnb = "/" + router.currentRoute.value.fullPath.split("/")[1]
    // },
    methods: {
        expandMenu(openFlag) {
            this.subOpen = openFlag
        },
        clickGnb(gnd) {
            this.activeGnb = gnd
        },
    },
    data() {
        return {
            'gnbList': gnbList,
            'subOpen': false,
            'activeGnb': null
        }
    }
}
</script>

<style scoped>
@import "@/assets/css/gnb.css"
</style>